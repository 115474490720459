<template>
  <div class="wap-library">
    <headerBar
      :title="$t('shang-pin-ku-0')"
      @back="back"
      right-icon="search"
      @detail="toSearch"
    ></headerBar>
    <div class="main">
      <div class="filter-line flex-center">
        <div class="option flex-center-between" @click="changeCategory(1)">
          <span class="name">{{
            form.category1 ? form.categoryName1 : $t('yi-ji-fen-lei')
          }}</span>
          <span>
            <i class="el-icon-caret-bottom"></i>
          </span>
        </div>
        <div class="option flex-center-between" @click="changeCategory(2)">
          <span class="name">{{
            form.category2 ? form.categoryName2 : $t('er-ji-fen-lei')
          }}</span>
          <span>
            <i class="el-icon-caret-bottom"></i>
          </span>
        </div>
        <div class="option flex-center-between" @click="changeCategory(3)">
          <span class="name">{{
            form.category3 ? form.categoryName3 : $t('san-ji-fen-lei')
          }}</span>
          <span>
            <i class="el-icon-caret-bottom"></i>
          </span>
        </div>
      </div>
      <!-- <div class="product-list"> -->
      <List
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.noMore')"
        @load="onLoad"
      >
        <goodsList
          :list="goodsList"
          :showCheck="true"
          type="seller"
          :hideDetail="true"
          @check="checkGoods"
        ></goodsList>
      </List>
      <!-- </div> -->
    </div>

    <div class="bottom-action flex-center-between" v-if="isShopAuth">
      <div class="flex-center">
        <Checkbox icon-size="18" v-model="checkedAll" @click="checkAll">{{
          $t('quan-xuan')
        }}</Checkbox>
        <span class="label"
          >{{ $t('yi-xuan') }}
          {{ checkedList.length }}
        </span>
      </div>
      <div>
        <Button class="btn2" @click="addToShop">{{
          $t('jia-ru-dian-pu')
        }}</Button>
      </div>
    </div>

    <div v-else class="gray-btn-box flex-center">店铺认证尚未通过</div>

    <Popup v-if="showPopup" v-model="showPopup" position="bottom">
      <Picker
        show-toolbar
        @cancel="cancel"
        @confirm="chooseCategory"
        :columns="columns"
        value-key="title"
      ></Picker>
    </Popup>

    <Popup v-if="showAddPopup" v-model="showAddPopup" round>
      <div class="add-product-modal">
        <div class="wap-form main">
          <div class="form-item">
            <div class="label">{{ $t('li-run-bi-li') }}</div>
            <Field
              v-model="editForm.ProfitPercentage"
              label=""
              :placeholder="$t('qing-shu-ru-li-run-bi-li-3')"
            >
              <template #button> % </template>
            </Field>
          </div>
          <div class="form-item">
            <div class="label">{{ $t('zhe-kou-kai-shi-ri-qi') }}</div>
            <Field
              v-model="editForm.start"
              readonly
              label=""
              :placeholder="$t('qing-xuan-ze')"
              @click="changeStart(1)"
            ></Field>
          </div>
          <div class="form-item">
            <div class="label">{{ $t('zhe-kou-jie-shu-ri-qi') }}</div>
            <Field
              v-model="editForm.end"
              readonly
              label=""
              :placeholder="$t('qing-xuan-ze-0')"
              @click="changeStart(2)"
            ></Field>
          </div>
          <div class="form-item">
            <div class="label">{{ $t('zhe-kou-bi-li') }}</div>
            <Field
              v-model="editForm.DiscountPercentage"
              label=""
              :placeholder="$t('qing-shu-ru-zhe-kou-bi-li')"
            >
              <template #button> % </template>
            </Field>
          </div>

          <Button type="info" class="submit-btn" @click="doAdd">{{
            $t('tian-jia')
          }}</Button>
        </div>
      </div>
    </Popup>

    <Popup v-model="showDatePopup" position="bottom">
      <DatetimePicker
        v-model="dateTime"
        type="date"
        title="$t('xuan-ze-nian-yue-ri')"
        @cancel="cancel"
        @confirm="chooseDate"
      ></DatetimePicker>
    </Popup>
  </div>
</template>
<script>
import {
  Button,
  Popup,
  Checkbox,
  Field,
  Dialog,
  List,
  Picker,
  DatetimePicker
} from 'vant'
import headerBar from '@/components/header'
import { productList, addProduct } from '@/api/shop'
import goodsList from '@/components/goodsList/wapList.vue'
import moment from 'moment'
export default {
  components: {
    Button,
    goodsList,
    headerBar,
    Checkbox,
    DatetimePicker,
    Popup,
    Field,
    List,
    Picker
  },
  data() {
    return {
      form: {
        category: '',
        category1: '',
        category2: '',
        category3: '',
        categoryName1: '',
        categoryName2: '',
        categoryName3: ''
      },
      dateTime: [],
      editForm: {
        date: [],
        ProfitPercentage: '',
        DiscountPercentage: ''
      },
      checkedAll: false,
      showPopup: false,
      showAddPopup: false,
      showDatePopup: false,
      page: {
        current: 1,
        size: 10
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1,
      goodsList: [],
      checkedList: [],
      columns: [],
      categoryType: '',
      childCategory: [],
      subChildCategory: [],
      timeFlag: ''
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    categoryList() {
      return this.$store.state.categoryList
    },
    isShopAuth() {
      return this.$store.state.isShopAuth
    }
  },
  mounted() {
    this.init()
    this.initCategory()
  },
  methods: {
    initCategory() {
      this.$store.dispatch('initCategory', this.lang)
    },
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    init(isAdd) {
      this.loading = true
      productList({
        current: this.page.current,
        pageSize: this.page.size,
        CategoryId: this.form.category || null
      })
        .then((res) => {
          if (isAdd) {
            this.goodsList = this.goodsList.concat(res.data.Items)
          } else {
            this.goodsList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    back() {
      this.$router.go(-1)
    },
    cancel() {
      this.showPopup = false
    },
    checkAll() {
      this.goodsList.forEach((v, i) => {
        this.$set(this.goodsList[i], 'checked', this.checkedAll)
      })
      this.getCheckList()
    },
    checkGoods(checked, i) {
      this.goodsList[i].checked = checked
      this.getCheckList()
      if (this.checkedList.length == this.goodsList.length) {
        this.checkedAll = true
      } else {
        this.checkedAll = false
      }
    },
    getCheckList() {
      this.checkedList = this.goodsList.filter((v) => {
        return v.checked
      })
    },
    changeCategory(type) {
      this.categoryType = type
      if (type == 1) {
        this.columns = this.categoryList.map((v) => {
          return {
            ...v,
            children: null,
            list: v.children
          }
        })
      } else if (type == 2) {
        if (!this.form.category1) {
          this.$toast(this.$t('qing-xian-xuan-ze-yi-ji-fen-lei'))
          return
        }
        this.columns = this.childCategory.map((v) => {
          return {
            ...v,
            children: null,
            list: v.children
          }
        })
      } else if (type == 3) {
        if (!this.form.category2) {
          this.$toast(this.$t('qing-xian-xuan-ze-er-ji-fen-lei'))
          return
        }
        this.columns = JSON.parse(JSON.stringify(this.subChildCategory))
      }
      this.columns.splice(0, 0, {
        ID: '',
        title: this.$t('quan-bu')
      })
      this.showPopup = true
    },
    addToShop() {
      if (this.checkedList.length === 0) {
        this.$toast(this.$t('qing-gou-xuan-shu-ju'))
        return
      }
      this.showAddPopup = true
    },
    doAdd() {
      if (!this.editForm.ProfitPercentage) {
        this.$toast(this.$t('qing-shu-ru-li-run-bi-li-3'))
        return
      }
      if(this.editForm.ProfitPercentage <= 0 ) {
        // 利润必须高于0
        this.$toast(this.$t('li-run-bi-li-bi-xu-gao-yu-0'))
        return
      }
      // if (!this.editForm.start) {
      //   this.$toast(this.$t('qing-xuan-ze-zhe-kou-kai-shi-ri-qi'))
      //   return
      // }
      // if (!this.editForm.end) {
      //   this.$toast(this.$t('qing-xuan-ze-zhe-kou-jie-shu-ri-qi'))
      //   return
      // }
      // if (!this.editForm.DiscountPercentage) {
      //   this.$toast(this.$t('qing-shu-ru-zhe-kou-bi-li'))
      //   return
      // }
      addProduct({
        ProductID: this.checkedList.map((v) => {
          return v.ID
        }),
        ProfitPercentage: parseFloat(this.editForm.ProfitPercentage),
        DiscountStartDate: moment(this.editForm.start).format('YYYY-MM-DD'),
        DiscountEndDate: moment(this.editForm.end).format('YYYY-MM-DD'),
        DiscountPercentage: parseFloat(this.editForm.DiscountPercentage)
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('tian-jia-cheng-gong'))
          this.checkedList = []
          this.showAddPopup = false
          this.editForm = {}
        } else {
          this.$toast(res.msg)
        }
      })
    },
    chooseCategory(e) {
      this.showPopup = false
      this.form[`categoryName${this.categoryType}`] = e.title
      this.form[`category${this.categoryType}`] = e.ID
      if (this.categoryType == 1) {
        this.childCategory = e.list
        if (e.ID == '') {
          this.form.category3 = ''
          this.form.category2 = ''
          this.form.categoryName3 = ''
          this.form.categoryName2 = ''
        }
      } else if (this.categoryType == 2) {
        this.subChildCategory = e.list
        if (e.ID == '') {
          this.form.category3 = ''
          this.form.categoryName3 = ''
        }
      }
      this.form.category = e.ID
      this.page.current = 1
      this.init()
    },
    toSearch() {
      this.$router.push({
        name: 'wapSearch',
        query: {
          type: 2
        }
      })
    },
    changeStart(flag) {
      this.timeFlag = flag
      this.showDatePopup = true
      let date = flag == 1 ? this.editForm.start : this.editForm.end
      this.dateTime = date ? new Date(date) : new Date()
    },
    cancel() {
      this.showDatePopup = false
    },
    chooseDate(e) {
      if (this.timeFlag == 1) {
        this.editForm.start = moment(e).format('YYYY-MM-DD')
      } else {
        this.editForm.end = moment(e).format('YYYY-MM-DD')
      }
      this.showDatePopup = false
    }
  }
}
</script>